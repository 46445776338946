import { NgModule } from "@angular/core";
import { PreloadAllModules, Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./core/guards/auth-route.guard";
import { SignInGuardService } from "./core/guards/signin-route.guard";
import { HomeRouteGuard } from "./core/guards/home-route.guard";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomePageModule),
    canActivate: [HomeRouteGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
    canActivate: [SignInGuardService],
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
  },
  {
    path: "terms-conditions",
    loadChildren: () =>
      import("./modules/public/terms-conditions/terms-conditions.module").then(
        (m) => m.TermsConditionsPageModule
      ),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./modules/public/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
