import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AmplifyService } from "aws-amplify-angular";
import { AppDataService } from "../services/app-data.service";
import { UtilsService } from "../services/utils.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userId: string; // user sub
  userEmail: string;
  userName: string;

  constructor(
    private router: Router,
    private amplifyService: AmplifyService,
    private appDataService: AppDataService,
    private utilsService: UtilsService
  ) {}

  /** sign-up */
  public async signUp(username: string, email: string, password: string) {
    return await this.amplifyService.auth().signUp({
      username,
      password,
      attributes: {
        email,
      },
    });
  }

  /** confirm code */
  public async confirmSignUp(username: string, code: string) {
    return await this.amplifyService.auth().confirmSignUp(username, code);
  }

  /** resend confirm code */
  public async resendConfirmationCode(username: string) {
    return await this.amplifyService.auth().resendSignUp(username);
  }

  /** sign-in */
  public async signIn(username: string, password: string) {
    await this.amplifyService.auth().signIn(username, password);
    return await this.isAuthenticated();
  }

  /** get authentication state */
  public async isAuthenticated() {
    try {
      const userData = await this.amplifyService
        .auth()
        .currentAuthenticatedUser();
      this.userName = userData.username;
      this.userId = userData.attributes.sub;
      this.userEmail = userData.attributes.email;
    } catch (e) {
      this.userId = "";
      this.userEmail = "";
      this.userName = "";
      return false;
    }
    if (this.userId) {
      return true;
    }
    return false;
  }

  public async getUserAttributes() {
    const userData = await this.amplifyService
      .auth()
      .currentAuthenticatedUser();
    //console.log("User Data is:");
    //console.log(userData);
    return userData.attributes;
  }

  /** signout */
  public async signOut() {
    return await this.amplifyService
      .auth()
      .signOut()
      .then(() => {
        //console.log("nullify all user data");
        this.utilsService.reloadCaseData = false;
        this.utilsService.reloadContactData = false;
        this.utilsService.reloadUserData = false;
        this.userId = null;
        this.userEmail = null;
        this.appDataService.clearAppData();
        this.router.navigate(["/auth"]);
      });
  }

  public async forgotPassword(username: string) {
    return await this.amplifyService.auth().forgotPassword(username);
  }

  public async forgotPasswordSubmit(
    username: string,
    code: string,
    new_password: string
  ) {
    return await this.amplifyService
      .auth()
      .forgotPasswordSubmit(username, code, new_password);
  }

  public async gotoSingInPage() {
    await this.router.navigate(["/auth"]);
  }

  public getUserId() {
    return this.userId;
  }
}
