export class PersonName {
    firstName: string;
    lastName: string;

    constructor(
        firstName: string = '',
        lastName: string = ''
    ) { 
        this.firstName = firstName;
        this.lastName = lastName;
    }

    getFullName() {
        if (!this.firstName && !this.lastName) {
            return ''
        }
        if (this.firstName && !this.lastName) {
            return this.firstName.trim()
        }
        if (!this.firstName && this.lastName) {
            return this.lastName.trim()
        }
        return this.firstName.trim() + ' ' + this.lastName.trim();
    }
}