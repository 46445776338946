import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class HomeRouteGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private platform: Platform) {}

  async canActivate() {
    const isWebPlatform =
      this.platform.is('mobileweb') || this.platform.is('desktop');
    if (!isWebPlatform) {
      this.router.navigate(['/auth']);
    }
    return isWebPlatform;
  }

  canActivateChild() {
    return this.canActivate();
  }
}
