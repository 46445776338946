import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import AppConfig from "src/app/configs/app-config";

@Injectable({
  providedIn: "root",
})
export class FileStorageService {
  constructor(private amplifyService: AmplifyService) {}

  async getUserAvatar() {
    return await this.amplifyService
      .storage()
      .get(AppConfig.avatar_file_name, { level: "protected" });
  }

  async putUserAvatar(fileContent: any) {
    return await this.amplifyService
      .storage()
      .put(AppConfig.avatar_file_name, fileContent, {
        level: "protected",
        contentType: fileContent.type,
      });
  }

  async deleteUserAvatar() {
    return await this.amplifyService
      .storage()
      .remove(AppConfig.avatar_file_name, {
        level: "protected",
      });
  }

  async getContactAvatar(identityId: string) {
    return await this.amplifyService.storage().get(AppConfig.avatar_file_name, {
      level: "protected",
      identityId: identityId,
    });
  }

  async getCasePropertyImage(caseId: string) {
    return await this.amplifyService
      .storage()
      .get(getPropertyImageFileName(caseId, AppConfig.property_file_name), {
        level: "public",
      });
  }

  async getCasePropertyThumbnail(caseId: string) {
    return await this.amplifyService
      .storage()
      .get(getPropertyImageThumbnail(caseId, AppConfig.property_file_name), {
        level: "public",
      });
  }

  async putCasePropertyImage(caseId: string, fileContent: any) {
    return await this.amplifyService
      .storage()
      .put(
        getPropertyImageFileName(caseId, AppConfig.property_file_name),
        fileContent,
        {
          level: "public",
          contentType: fileContent.type,
        }
      );
  }

  async deleteCasePropertyImage(caseId: string) {
    return await this.amplifyService
      .storage()
      .remove(getPropertyImageFileName(caseId, AppConfig.property_file_name), {
        level: "public",
      });
  }

  async getCaseFile(caseId: string, caseFileName: string) {
    return await this.amplifyService
      .storage()
      .get(getCaseFileName(caseId, caseFileName), {
        level: "public",
      });
  }

  async getCaseFileThumbnail(caseId: string, caseFileName: string) {
    return await this.amplifyService
      .storage()
      .get(getCaseFileThumbnail(caseId, caseFileName), {
        level: "public",
      });
  }

  async putCaseFile(
    caseId: string,
    caseFileName: string,
    fileContent: any,
    contentType: string
  ) {
    return await this.amplifyService
      .storage()
      .put(getCaseFileName(caseId, caseFileName), fileContent, {
        level: "public",
        contentType: contentType,
      });
  }

  async deleteCaseFile(caseId: string, caseFileName: string) {
    return await this.amplifyService
      .storage()
      .remove(getCaseFileName(caseId, caseFileName), {
        level: "public",
      });
  }
}

function getPropertyImageFileName(caseId: string, propertyFileName: string) {
  return "original/" + caseId + "-" + propertyFileName;
}

function getPropertyImageThumbnail(caseId: string, propertyFileName: string) {
  return "thumbnail/resized-" + caseId + "-" + propertyFileName;
}

function getCaseFileName(caseId: string, caseFileName: string) {
  return "original/" + "cfile" + "-" + caseId + "-" + caseFileName;
}

function getCaseFileThumbnail(caseId: string, caseFileName: string) {
  return "thumbnail/resized-" + "cfile" + "-" + caseId + "-" + caseFileName;
}
