// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const AppConfig = {
  default_user_avatar: "assets/avatar/default_avatar.svg",
  default_camera_avatar: "assets/avatar/default_user_with_camera.svg",
  image_loading_avatar: "assets/avatar/avatar_loading.svg",
  loading_error_avatar: "assets/avatar/avatar_loading_error.svg",
  avatar_file_name: "avatar",

  default_property_picture: "assets/house/default_house.svg",
  image_loading_property: "assets/house/property_image_loading.svg",
  loading_error_property: "assets/house/property_image_loading_error.svg",
  property_file_name: "propertyPicture",

  default_condo_picture: "assets/house/condo.svg",
  default_coop_picture: "assets/house/co-op.svg",
  default_duplex_picture: "assets/house/duplex.svg",
  default_land_picture: "assets/house/land.svg",
  default_multifamily_picture: "assets/house/multifamily.svg",
  default_singlefamily_picture: "assets/house/single_family.svg",
  default_townhouse_picture: "assets/house/townhouse.svg",
  default_triplex_picture: "assets/house/triplex.svg",

  image_file_size: 10, //10MB
  file_size: 20, //20MB
  case_file_num: 20, // single case file number

  invitationStatus: {
    FINISHED: 1,
    ACCEPTED: 0,
    PENDING: -1,
    DECLINED: -2,
    BLOCKED: -3,
  },

  case_sections: [
    {
      section: "caseSummary",
      sectionName: "Case Summary",
      sectionId: "case_summary",
    },
    {
      section: "closingTeam",
      sectionName: "Closing Team",
      sectionId: "closing_team",
    },
    {
      section: "propertyProfile",
      sectionName: "Property Profile",
      sectionId: "property_profile",
    },
    {
      section: "caseDocuments",
      sectionName: "Case Documents",
      sectionId: "case_documents",
    },
    {
      section: "offerToPurchase",
      sectionName: "Offer to Purchase",
      sectionId: "case_offer",
    },
    {
      section: "propertyInspection",
      sectionName: "Property Inspection",
      sectionId: "property_inspection",
    },
    {
      section: "pnsAgreement",
      sectionName: "Purchase & Sale Agreement",
      sectionId: "case_pns",
    },
    {
      section: "mortgage",
      sectionName: "Mortage",
      sectionId: "case_mortgage",
    },
    {
      section: "closing",
      sectionName: "Closing",
      sectionId: "case_closing",
    },
    {
      section: "comments",
      sectionName: "Comments",
      sectionId: "case_comments",
    },
    {
      section: "logs",
      sectionName: "Logs",
      sectionId: "case_logs",
    },
  ],
};

export default AppConfig;
