import { Injectable } from "@angular/core";
import { FileStorageService } from "./file-storage.service";
import AppConfig from "src/app/configs/app-config";
import { PersonName } from "src/app/shared/classes/PersonName.class";
import {
  AlertController,
  LoadingController,
  PickerController,
} from "@ionic/angular";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { EmailComposer } from "@ionic-native/email-composer/ngx";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  public userId: string;
  public reloadCaseData = false;
  public reloadContactData = false;
  public reloadUserData = false;
  public isLoading = false;
  public selectedItem = "";
  public signUpUsername = "";
  public thumbnailData: any = {};
  public avatarData: any = {};
  public userMapData: any = {};
  public defaultUserAvatar = AppConfig.default_user_avatar;
  public defaultHouseThumbnail = AppConfig.default_property_picture;

  constructor(
    private fileStorageService: FileStorageService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private pickerController: PickerController,
    private callNumber: CallNumber,
    private emailComposer: EmailComposer
  ) {}

  isEmptyOrSpaces(str: string) {
    if (!str) return true;
    return str.match(/^ *$/) !== null;
  }

  isValidEmail(email: string) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  checkPassword(event: any) {
    let pwStr = event.detail.value;
    const upperCase = new RegExp("[A-Z]"); //For Uppercase Alphabet
    const lowerCase = new RegExp("[a-z]"); //For Lowercase Alphabet
    const numExp = new RegExp("[0-9]"); //For Numeric Digits
    const specialChar = new RegExp("[$@$!%*#?&]"); //For Special Characters

    let passwordcheck: any = {};
    passwordcheck.isLengthThan8 = pwStr.length >= 8;
    passwordcheck.isContainingNum = numExp.test(pwStr);
    passwordcheck.isContainingSpecial = specialChar.test(pwStr);
    passwordcheck.isContainingUpper = upperCase.test(pwStr);
    passwordcheck.isContainingLower = lowerCase.test(pwStr);

    return passwordcheck;
  }

  joinStrArray(strArray: Array<string>, sep: string) {
    strArray = strArray.filter((s) => {
      return !this.isEmptyOrSpaces(s);
    });
    return strArray.join(sep);
  }

  areAllFieldsEmpty(inputObj: any) {
    for (let objProperty in inputObj) {
      if (
        inputObj[objProperty] &&
        !this.isEmptyOrSpaces(inputObj[objProperty])
      ) {
        return false;
      }
    }
    return true;
  }

  getDateTime(timeValue: any) {
    if (!timeValue) {
      return "";
    }
    let date = new Date(timeValue);
    return (
      date.toLocaleDateString("en-US") + ": " + date.toLocaleTimeString("en-US")
    );
  }

  formatPhoneNumber(phoneNumberString: string) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  getFullName(people: any) {
    if (!people) return "";
    const memberName = new PersonName(people.firstName, people.lastName);
    return memberName.getFullName();
  }

  getRoleAndOrgStr(role: string, organization: string) {
    if (!role && !organization) return "";

    if (!role && organization) {
      return organization;
    }
    if (role && !organization) {
      return role;
    }
    return [role, organization].join(", ");
  }

  getCaseAddressLineToDisplay(caseItem: any, lineNum: number) {
    if (!caseItem) return "";
    let address = {
      line1: caseItem.propertyAddressLine1 || "",
      line2: caseItem.propertyAddressLine2 || "",
      line3: caseItem.propertyAddressLine3 || "",
      city: caseItem.propertyCity || "",
      state: caseItem.propertyState || "",
      zipCode: caseItem.propertyZipCode || "",
    };
    return this.getAddressLineToDisplay(address, lineNum);
  }

  getCaseAddressLastLine(caseItem: any) {
    if (!caseItem) return "";
    let address = {
      city: caseItem.propertyCity || "",
      state: caseItem.propertyState || "",
      zipCode: caseItem.propertyZipCode || "",
    };
    return this.getAddressLastLine(address);
  }

  getContactAddressLastLine(caseItem: any) {
    if (!caseItem) return "";
    let address = {
      city: caseItem.closingContactCity || "",
      state: caseItem.closingContactState || "",
      zipCode: caseItem.closingContactZipCode || "",
    };
    return this.getAddressLastLine(address);
  }

  getAddressLineToDisplay(address: any, lineNum: number) {
    if (!address) return "";
    if (lineNum === 1) return this.getAddressLine1ToDisplay(address);
    if (lineNum === 2) return this.getAddressLine2ToDisplay(address);
    return "";
  }

  getAddressLine1ToDisplay(address: any) {
    if (!address) return "";
    if (!address.line1) return "";
    if (
      !address.line1 &&
      !address.line2 &&
      !address.line3 &&
      !address.city &&
      !address.state &&
      !address.zipCode
    )
      return "";

    if (!address.line2 && !address.line3) {
      return (
        address.line1 +
        ", " +
        address.city +
        ", " +
        address.state +
        " " +
        (address.zipCode || "")
      );
    }
    if (!address.line3) {
      return address.line1 + ", " + address.line2;
    }
    if (!address.line2) {
      return address.line1 + ", " + address.line3;
    }
    return address.line1 + ", " + address.line2 + ", " + address.line3;
  }

  getAddressLine2ToDisplay(address: any) {
    if (!address.line2 && !address.line3) {
      return "";
    }
    return this.getAddressLastLine(address);
  }

  getAddressLastLine(address: any) {
    if (!address) return "";
    let shortStr = (
      (address.city || "") +
      (address.state || "") +
      (address.zipCode || "")
    ).trim();
    if (!shortStr) return "";
    return (
      address.city +
      ", " +
      address.state +
      " " +
      (address.zipCode || "")
    ).trim();
  }

  getClosingDateStr(closingTime: number) {
    if (!closingTime) return "";
    let date = new Date(closingTime);
    return "Closing Date: " + date.toLocaleDateString("en-US");
  }

  async openItemPicker(roleName: string, pickerOptions: Array<string>) {
    this.selectedItem = "";
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Apply",
          handler: (value) => {
            this.selectedItem = value[roleName].text;
          },
        },
      ],
      columns: [
        {
          name: roleName,
          options: this.getPickerOptions(pickerOptions),
        },
      ],
    });

    return await picker.present();
  }

  getPickerOptions(optionArray: Array<string>) {
    let options = [];
    for (let i in optionArray) {
      options.push({
        text: optionArray[i],
        value: i,
      });
    }
    return options;
  }

  async showAlertMessage(message: string) {
    const alert = await this.alertController.create({
      message: message,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });

    await alert.present();
  }

  async getDefaultThumbnail(caseId: string, event: CustomEvent) {
    this.thumbnailData[caseId] = this.defaultHouseThumbnail;
  }

  async updatePropertyThumbnailData(caseId: string, event: CustomEvent = null) {
    if (!caseId) {
      //console.log("==== Invalid caseId ===");
      //console.log(caseId);
      return null;
    }

    if (event) {
      //console.log("fired property picture thumbnail load error event:");
      //console.log(event);
      const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      //console.log("Waited 1s");
    }

    try {      
      this.thumbnailData[caseId] =
        await this.fileStorageService.getCasePropertyThumbnail(caseId);
    } catch (err) {
      // fail to load property picture data
      //console.log("failed to load property picture for: " + caseId);
      //console.log(err);
    }
    if (!this.thumbnailData[caseId]) {
      this.thumbnailData[caseId] = this.defaultHouseThumbnail;
    }
    return this.thumbnailData[caseId];
  }

  getUserIdentityId(userId: string) {
    return this.userMapData[userId]["identityId"];
  }

  getAvatarByUserId(userId: string) {
    if (this.userMapData && this.userMapData[userId])
      return this.avatarData[this.userMapData[userId]["identityId"]] || "";
    return "";
  }

  async updateUserAvatarDataByUserId(
    userId: string,
    event: CustomEvent = null
  ) {
    if (this.userMapData && this.userMapData[userId])
      return await this.updateUserAvatarData(
        this.userMapData[userId]["identityId"],
        event
      );
    return "";
  }

  async updateUserAvatarData(identityId: string, event: CustomEvent = null) {
    if (!identityId) {
      //console.log("==== Invalid identityId ===");
      return null;
    }

    if (event) {
      //console.log("fired avatar load error event:");
      //console.log(event);
    }

    try {
      let userAvatar = await this.fileStorageService.getContactAvatar(
        identityId
      );
      if (userAvatar) this.avatarData[identityId] = userAvatar;
    } catch (err) {
      // fail to load avatar data
      //console.log("failed to load avatar for: " + identityId);
      //console.log(err);
    }
    if (!this.avatarData[identityId]) {
      this.avatarData[identityId] = "";
    }
    return this.avatarData[identityId];
  }

  sortCaseMembers(caseMembers: any, adminId: string, userId: string) {
    if (caseMembers) {
      caseMembers.sort(function (a: any, b: any) {
        if (b.memberId === adminId) return 1;
        if (a.memberId === adminId) return -1;
        if (b.memberId === userId) return 1;
        if (a.memberId === userId) return -1;
        if (
          a.caseViewTime <= 0 &&
          b.caseViewTime <= 0 &&
          a.caseViewTime === b.caseViewTime
        )
          return 0;
        if (b.caseViewTime === AppConfig.invitationStatus.ACCEPTED) return 1;
        if (a.caseViewTime === AppConfig.invitationStatus.ACCEPTED) return -1;
        if (b.caseViewTime === AppConfig.invitationStatus.DECLINED) return -1;
        if (a.caseViewTime === AppConfig.invitationStatus.DECLINED) return 1;
        return 0;
      });
    }

    return caseMembers;
  }

  async presentLoading(
    loadingMsg: string = "Loading data ...",
    durationTime: number = 3000
  ) {
    this.isLoading = true;
    const loading = await this.loadingController.create({
      message: loadingMsg,
      duration: durationTime,
    });
    return await loading.present().then(() => {
      //console.log("presented");
      if (!this.isLoading) {
        loading.dismiss();
      }
    });
  }

  async dismissLoading() {
    let topLoading = await this.loadingController.getTop();
    if (topLoading) {
      this.loadingController.dismiss();
    } else {
      this.isLoading = false;
    }
  }

  async callPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) return;

    const alert = await this.alertController.create({
      header: "Call " + this.formatPhoneNumber(phoneNumber) + "?",
      buttons: [
        {
          text: "No",
          role: "cancel",
        },
        {
          text: "Yes",
          handler: () => {
            this.callNumber.callNumber(phoneNumber, true);
          },
        },
      ],
    });

    await alert.present();
  }

  async sendEmailTo(emailAddress: string) {
    if (!emailAddress) return;

    const alert = await this.alertController.create({
      header: "Send email to\n" + emailAddress + "?",
      buttons: [
        {
          text: "No",
          role: "cancel",
        },
        {
          text: "Yes",
          handler: () => {
            let email = {
              to: emailAddress,
            };
            //console.log("trying to open email IDE");
            this.emailComposer.open(email);
          },
        },
      ],
    });

    await alert.present();
  }

  async makeCall(phoneNumbers: Array<string>) {
    if (phoneNumbers.length === 0) {
      this.showAlertMessage("No phone number to call.");
      return;
    }

    if (phoneNumbers.length === 1) {
      //console.log("try to call " + phoneNumbers[0]);
      return this.callNumber.callNumber(phoneNumbers[0], true);
    }

    let callNumber = "";
    let phoneNumberOptions = [];
    phoneNumbers.forEach((element) =>
      phoneNumberOptions.push(this.formatPhoneNumber(element))
    );

    const picker = await this.pickerController.create({
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Call",
          handler: (value) => {
            callNumber = value.PhoneNumbers.text;
          },
        },
      ],
      columns: [
        {
          name: "PhoneNumbers",
          options: this.getPickerOptions(phoneNumberOptions),
        },
      ],
    });

    await picker.present();
    await picker.onDidDismiss();

    if (!callNumber) return;

    //console.log("try to call " + callNumber);
    return this.callNumber.callNumber(callNumber, true);
  }

  async makeEmail(emails: Array<string>) {
    if (emails.length === 0) {
      this.showAlertMessage("No email address to send.");
      return;
    }

    if (emails.length === 1) {
      //console.log("try to send email to " + emails[0]);
      let email = {
        to: emails[0],
      };
      return this.emailComposer.open(email);
    }

    let selectedOption = "";
    let emailOptions = emails;
    emailOptions.push("All Above");
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Email to",
          handler: (value) => {
            selectedOption = value.Emails.text;
          },
        },
      ],
      columns: [
        {
          name: "Emails",
          options: this.getPickerOptions(emailOptions),
        },
      ],
    });

    await picker.present();
    await picker.onDidDismiss();

    if (!selectedOption) return;

    let toEmails = null;
    if (selectedOption === "All Above") {
      toEmails = emails;
    } else {
      toEmails = selectedOption;
    }

    let email = {
      to: toEmails,
    };
    //console.log("try to send email to " + selectedOption);
    return this.emailComposer.open(email);
  }

  /*  setInputFocus(inputElement:any) {
    setTimeout(async () => {                              
      await inputElement.setFocus();      
    }, 600); 
  } */
}
