/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:8d6f3c53-e61b-4178-82f5-9c0c597f96ad",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_gi7VoctMB",
  aws_user_pools_web_client_id: "4q41th3apvpjghsgk69tqsrbp8",
  oauth: {},
  aws_user_files_s3_bucket: "closingdayfiles150320-prod",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_dynamodb_all_tables_region: "us-east-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "closingdaytable-prod",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom: [
    {
      name: "closingdayapi",
      endpoint: "https://jd6w08fw13.execute-api.us-east-1.amazonaws.com/prod",
      region: "us-east-1",
    },
  ],
};

export default awsmobile;
