import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../authentication/authentication.service';

@Injectable()
export class SignInGuardService implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private auth: AuthService) { }

    async canActivate() {
        let isSignedIn = await this.auth.isAuthenticated();
        if (isSignedIn) {
            this.router.navigate(['/tabs']);
        }
        return !isSignedIn;
    }

    canActivateChild() {
        return this.canActivate();
    }
}