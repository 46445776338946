import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { CaseData } from "src/app/shared/classes/CaseData.class";
import { CaseFile } from "src/app/shared/classes/CaseFile.class";
import { CaseMember } from "src/app/shared/classes/CaseMember.class";
import { CaseMessage } from "src/app/shared/classes/CaseMessage.class";
import { Contact } from "src/app/shared/classes/Contact.class";

@Injectable({
  providedIn: "root",
})
export class AppDataService {
  apiName = "closingdayapi";

  caseListPath = "/data/cases";
  caseItemPath = "/data/cases-item";
  caseLockPath = "/data/cases-lockunlock";

  caseMemberPath = "/data/case-member";
  caseInvitationPath = "/data/case-invitation";

  caseFilePath = "/data/case-file";

  caseCommentPath = "/data/case-comment";
  moreCaseCommentsPath = "/data/case-more-comments";

  moreCaseLogsPath = "/data/case-more-logs";

  caseMessageListPath = "/data/case-messages";
  caseMessageItemPath = "/data/case-messages-item";
  moreCaseMessagesPath = "/data/case-more-messages";

  contactListPath = "/data/contacts";
  contactItemPath = "/data/contacts-item";
  contactPreviewPath = "/data/contacts-preview";
  contactReplyPath = "/data/contacts-reply";

  settingItemPath = "/data/setting";

  caseListData: any;
  caseListUserData: any;
  caseData: any;
  messageData: any;
  commentData: any;
  logData: any;
  signInCaseDataLoaded = false;
  hasNewCases = false;

  contactListItems: any;
  contactData: any;
  signedInContactDataLoaded: boolean = false;
  hasNewContacts = false;

  settingData: any;
  signInSettingDataLoaded: boolean = false;

  constructor(private amplifyService: AmplifyService) {}

  // clear case data
  clearAppData() {
    //console.log("API:clearAppData");
    this.caseListData = null;
    this.caseListUserData = null;
    this.caseData = null;
    this.signInCaseDataLoaded = false;

    this.contactListItems = null;
    this.contactData = null;
    this.signedInContactDataLoaded = false;

    this.settingData = null;
    this.signInSettingDataLoaded = false;
  }

  //API: init user case list
  async initCaseList(email: string) {
    //console.log("API:initCaseList");
    const params = {
      userEmail: email,
    };
    return await this.amplifyService
      .api()
      .post(this.apiName, this.caseListPath, {
        body: params,
      });
  }

  //API: get case list data
  async getCaseList(isSignedIn = false) {
    //console.log("API: getCaseList");
    const caseListPreviewData = await this.amplifyService
      .api()
      .get(this.apiName, this.caseListPath);

    if (caseListPreviewData) {
      this.caseListData = caseListPreviewData.caseList;
      this.caseListUserData = caseListPreviewData.userData;
      this.hasNewCases = caseListPreviewData.hasNewCases;
    }

    if (isSignedIn) {
      this.signInCaseDataLoaded = true;
    }

    //console.log(caseListPreviewData);
    return this.caseListData;
  }

  //API: get a single case
  async getCaseItem(caseId: string) {
    //console.log("API: getCaseItem");
    this.caseData = await this.amplifyService
      .api()
      .get(this.apiName, this.caseItemPath + `/${caseId}`);
    return this.caseData;
  }

  //API: create a new case
  async createCaseItem(caseItem: CaseData) {
    //console.log("API: createCaseItem");
    //console.log(caseItem);
    const responseData = await this.amplifyService
      .api()
      .post(this.apiName, this.caseItemPath, { body: caseItem });
    return responseData.caseId;
  }

  //API: update a case
  async updateCaseItem(caseId: string, updateData: any) {
    //console.log("API: updateCaseItem");
    let originalData = {};
    for (let attrName in updateData) {
      if (this.caseData.hasOwnProperty(attrName)) {
        originalData[attrName] = this.caseData[attrName];
      } else {
        originalData[attrName] = null;
      }
    }
    let latestCaseItem = await this.getCaseItem(caseId);

    for (let attrName in updateData) {
      if (!originalData[attrName] && !latestCaseItem[attrName]) {
        // pass through
      } else if (originalData[attrName] === latestCaseItem[attrName]) {
        // pass through
      } else {
        // no update before solving conflict
        return attrName;
      }
    }

    await this.amplifyService
      .api()
      .put(this.apiName, this.caseItemPath + `/${caseId}`, {
        body: updateData,
      });
    await this.getCaseItem(caseId);
  }

  // lock/unlock a case
  async setCaseItemLock(caseId: string, isToLock: boolean) {
    //console.log("API: setCaseItemLock");
    const lockData = {
      isLocked: isToLock,
    };
    await this.amplifyService
      .api()
      .put(this.apiName, this.caseLockPath + `/${caseId}`, { body: lockData });
    return await this.getCaseItem(caseId);
  }

  // delete a case
  async deleteCaseItem(caseId: string) {
    //console.log("API: deleteCaseItem");
    await this.amplifyService
      .api()
      .del(this.apiName, this.caseItemPath + `/${caseId}`);
    return await this.getCaseList(true);
  }

  // add a case member (existent user)
  async addCaseMember(memberItem: CaseMember) {
    //console.log("API: addCaseMember");
    const responseData = await this.amplifyService
      .api()
      .post(this.apiName, this.caseMemberPath, { body: memberItem });
    await this.getCaseItem(memberItem.caseId);
    return responseData;
  }

  // update a case member
  async updateCaseMember(
    memberId: string,
    caseId: string,
    attributeNameToUpdate: string,
    attributeValueToUpdate: any
  ) {
    //console.log("API: updateCaseMember");
    const memberUpdateData = {
      attributeName: attributeNameToUpdate,
      attributeValue: attributeValueToUpdate,
    };
    await this.amplifyService
      .api()
      .put(this.apiName, this.caseMemberPath + `/${memberId}/${caseId}`, {
        body: memberUpdateData,
      });
    return await this.getCaseItem(caseId);
  }

  // reply a case invitation
  async replyCaseInvitation(caseId: string, replyCode: number) {
    /* ACCEPTED(0),
       PENDING(-1), 
       DECLINED(-2), 
       BLOCKED(-3), */
    //console.log("API: replyCaseInvitation");
    await this.amplifyService
      .api()
      .put(this.apiName, this.caseInvitationPath + `/${caseId}`, {
        body: { replyCode: replyCode },
      });
    return await this.getCaseList(true);
  }

  // delete a case member
  async deleteCaseMember(memberId: string, caseId: string) {
    //console.log("API: deleteCaseMember");
    await this.amplifyService
      .api()
      .del(this.apiName, this.caseMemberPath + `/${memberId}/${caseId}`);
    return await this.getCaseItem(caseId);
  }

  // add a case file
  async addCaseFile(caseFile: CaseFile) {
    //console.log("API: addCaseFile");
    await this.amplifyService
      .api()
      .post(this.apiName, this.caseFilePath, { body: caseFile });
    return await this.getCaseItem(caseFile.caseId);
  }

  // update a case file
  async updateCaseFile(fileId: string, caseId: string, fileUpdateData: any) {
    //console.log("API: updateCaseFile");
    await this.amplifyService
      .api()
      .put(this.apiName, this.caseFilePath + `/${fileId}/${caseId}`, {
        body: fileUpdateData,
      });
    return await this.getCaseItem(caseId);
  }

  // delete a case file
  async deleteCaseFile(fileId: string, caseId: string) {
    //console.log("API: deleteCaseFile");
    await this.amplifyService
      .api()
      .del(this.apiName, this.caseFilePath + `/${fileId}/${caseId}`);
    return await this.getCaseItem(caseId);
  }

  // case comments
  // get another 100 comments
  async getMoreCaseComments(caseId: string, lastEvaluatedKey: any) {
    //console.log("API: getMoreCaseComments");
    this.commentData = await this.amplifyService
      .api()
      .get(
        this.apiName,
        this.moreCaseCommentsPath +
          `/${caseId}/${JSON.stringify(lastEvaluatedKey)}`
      );
    return this.commentData;
  }

  async addCaseComment(commentData: any) {
    //console.log("API: addCaseComment");
    await this.amplifyService
      .api()
      .post(this.apiName, this.caseCommentPath, { body: commentData });
    return await this.getCaseItem(commentData.caseId);
  }

  async deleteCaseComment(caseId: string, commentId: string) {
    //console.log("API: deleteCaseComment");
    await this.amplifyService
      .api()
      .del(this.apiName, this.caseCommentPath + `/${commentId}/${caseId}`);
    return await this.getCaseItem(caseId);
  }

  // get another 100 case logs
  async getMoreCaseLogs(caseId: string, lastEvaluatedKey: any) {
    //console.log("API: getMoreCaseLogs");
    this.logData = await this.amplifyService
      .api()
      .get(
        this.apiName,
        this.moreCaseLogsPath + `/${caseId}/${JSON.stringify(lastEvaluatedKey)}`
      );
    return this.logData;
  }

  // case messages
  async getCaseMessages(caseId: string, friendId: string, startTimeStamp = 0) {
    //console.log("API: getCaseMessages");
    this.messageData = await this.amplifyService
      .api()
      .get(
        this.apiName,
        this.caseMessageListPath + `/${caseId}/${friendId}/${startTimeStamp}`
      );
    return this.messageData;
  }

  // get another 100 case session messages
  async getMoreCaseMessages(
    caseId: string,
    friendId: string,
    lastEvaluatedKey: any
  ) {
    //console.log("API: getMoreCaseMessages");
    this.messageData = await this.amplifyService
      .api()
      .get(
        this.apiName,
        this.moreCaseMessagesPath +
          `/${caseId}/${friendId}/${JSON.stringify(lastEvaluatedKey)}`
      );
    return this.messageData;
  }

  // read case messages
  async readCaseMessages(
    caseId: string,
    friendId: string,
    unreadMsgIds: Array<string>
  ) {
    //console.log("API: readCaseMessages");
    return await this.amplifyService
      .api()
      .put(this.apiName, this.caseMessageListPath + `/${caseId}/${friendId}`, {
        body: unreadMsgIds,
      });
  }

  // send a case message
  async sendCaseMessage(messageData: CaseMessage) {
    //console.log("API: sendCaseMessage");
    await this.amplifyService
      .api()
      .post(this.apiName, this.caseMessageItemPath, { body: messageData });
  }

  /** init contact list data after registration **/
  async initContactList(userEmail: string) {
    //console.log("API: initContactList");
    let params = {
      userEmail: userEmail,
    };
    await this.amplifyService.api().post(this.apiName, this.contactListPath, {
      body: params,
    });
    return await this.getContactList(true);
  }

  /** get contact list data **/
  async getContactList(isSignedIn = false) {
    //console.log("API: getContactList");
    this.contactListItems = await this.amplifyService
      .api()
      .get(this.apiName, this.contactListPath);

    this.hasNewContacts = false;
    for (const contactItem of this.contactListItems) {
      // accepted or pending
      if (
        (!contactItem.isInviter && contactItem.invitationStatusCode === 0) ||
        (contactItem.isInviter && contactItem.invitationStatusCode === -1)
      ) {
        this.hasNewContacts = true;
        break;
      }
    }

    if (isSignedIn) {
      this.signedInContactDataLoaded = true;
    }

    return this.contactListItems;
  }

  /** get contact data **/
  async getContact(contactId: string, isInviter: boolean) {
    //console.log("API: getContact");
    /* return a Contact object*/
    this.contactData = await this.amplifyService
      .api()
      .get(this.apiName, this.contactItemPath + `/${contactId}/${+isInviter}`);
    return this.contactData;
  }

  /** get user preview**/
  async getUserPreview(keyString: string) {
    //console.log("API: getUserPreview");
    return await this.amplifyService
      .api()
      .get(this.apiName, this.contactPreviewPath + `/${keyString}`);
  }

  /** invite user **/
  async inviteUser(invitationValue: Contact) {
    //console.log("API: inviteUser");
    await this.amplifyService.api().post(this.apiName, this.contactItemPath, {
      body: invitationValue,
    });
    return await this.getContactList(true);
  }

  /** reply invitation **/
  async replyInvitation(inviterId: string, replyCode: number) {
    /* FINISHED(1)
       ACCEPTED(0),
       PENDING(-1), 
       DECLINED(-2), 
       BLOCKED(-3), */
    //console.log("API: replyInvitation");
    await this.amplifyService
      .api()
      .put(this.apiName, this.contactReplyPath + `/${inviterId}`, {
        body: { replyCode: replyCode },
      });
    return await this.getContactList(true);
  }

  /** delete finished contact **/
  async deleteContact(contactId: string, isInviter: boolean) {
    //console.log("API: deleteContact");
    await this.amplifyService
      .api()
      .del(
        this.apiName,
        this.contactItemPath + `/${contactId}` + `/${+isInviter}`
      );
    return await this.getContactList(true);
  }

  /** get user setting **/
  async getUserSetting() {
    //console.log("API: getUserSetting");
    this.settingData = await this.amplifyService
      .api()
      .get(this.apiName, this.settingItemPath);
    //console.log(this.settingData);
    return this.settingData;
  }

  /** init user setting **/
  async initUserSetting(userSetting: any = null) {
    //console.log("API: initUserSetting");
    await this.amplifyService
      .api()
      .post(this.apiName, this.settingItemPath, { body: userSetting });
    return await this.getUserSetting();
  }

  /** update user setting **/
  async updateUserSetting(updateData: any) {
    //console.log("API:updateUserSetting");
    //console.log(JSON.stringify(updateData));
    await this.amplifyService.api().put(this.apiName, this.settingItemPath, {
      body: updateData,
    });
    return await this.getUserSetting();
  }
}
